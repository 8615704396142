<template v-if="this.isLogin"> 
    <div class="Navbar" >
        <p id="titleNavbar">Kurnia Jaya Karya</p>
        <div class="rightSide">
            <p id="username"> {{ username }}
            </p>
            <button @click.prevent="showModalPassword()" title="ubah password">
                <img  src="../assets/user.png" id="userImg" alt="">
            </button>
            <button @click.prevent="logout()" title="keluar           ">
                <img  src="../assets/logout.png" id="userImg" alt="">
            </button>

        </div>
<!-- Modal -->
        <div class="modal fade" id="passwordModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Ubah Password</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="changePassword()">      
                            <p id="titleInput"> password </p>
                            <input type="text" placeholder="Password Baru" v-model="password" required minlength="5" maxlength="10">
                        <button id="submit" type="submit">
                            Ubah
                        </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "../API/axios"
import Swal from "sweetalert2";
export default {
name: "Navbar",
data() {
    return {
        password:"",
        username: ''
    }
},
created() {
  this.$store.dispatch('getLocalStorage')
  this.ceckUserName()
},
methods: {
    showModalPassword() {
        this.password = ""
        $('#passwordModal').modal('show')
    },
    logout() {
        this.$store.dispatch('getLocalStorage')
        Swal.fire(
            'Berhasil',
            'Anda telah Keluar',
            'success'
            )
        this.$store.commit("setIsLogin", false);
        localStorage.clear()
        this.$router.push("/login")
    },
    changePassword() {
        axios({
            method: "PATCH",
            url: "/user/password",
            data: {
                password: this.password
            },
            headers: {
              access_token: localStorage.getItem("access_token")
            }
        })
        .then(data => {
            Swal.fire(
                    'Berhasil',
                    'Anda telah menambahkan 1 karyawan',
                    'success'
                )
            $('#passwordModal').modal('hide')

        }).catch(err => {
            console.log(err);
        })
    },
    ceckUserName() {

        let data = ''
        if(localStorage.getItem('username')) {
            this.username = localStorage.getItem("username")
        }
        else {
            this.logout()
        }
    }
},
computed: {
  isLogin() {
    return this.$store.state.isLogin
},

},
}
</script>

<style scoped>
    .Navbar {
        display: flex;
    /* flex-direction: row; */
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 2rem;
        top: 0;
        border-bottom: #b5b5b5 1px solid;
        background-color: #6A80B9
        
    }

    #titleNavbar {
        font-size: 1rem;
        font-weight: 800;
        font-family: Avenir, Helvetica, Arial, sans-serif,;
        margin: 0
    }
    p {
        color: #ededed;
    }
    #userImg {
        width: 24px;
        height: 24px;
        color: #ededed;
    }
    button {
        border: none;
        background-color: transparent;
    }
    .rightSide{
        display: flex;
    }
    #username {
        /* font-family: 'Mountains of Christmas', serif; */
        margin: 0;
        font-weight: 700;
    }
</style>