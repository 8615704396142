import axios from 'axios';


export default axios.create({
	baseURL: 'https://dbsikuraja.otnielpangkung.com/',
});
// export default axios.create({
// 	baseURL: 'https://testdbsikuraja.otnielpangkung.com/',
// });
// export default axios.create({
// 	baseURL: 'http://localhost:3001',
// });
// export default axios.create({
// 	baseURL: 'http://192.168.11.11:3001',
// });

 