<template>
  <div class="mainPage">
    <p id="headPage">Laporan Surat Jalan / PO</p>
    <div class="filterRow">
      <div class="inRow">
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Dari Tanggl</p>
          <b-input type="date" v-model="startDate" @change="this.getDatatable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Sampai Tanggal</p>
          <b-input type="date" v-model="endDate" @change="this.getDatatable"></b-input>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Jenis</p>
          <b-select v-model="type" @change="this.getDatatable">
            <option value="0">Seluruh Kegiatan</option>
            <option value="1">Mobil Keluar / Masuk</option>
            <option value="2">PO</option>
          </b-select>
        </div>
        <div class="inCol" id="cardFilter">
          <p id="inputTitle">Keyword</p>
          <b-input type="text" v-model="keyword" @change="this.getDatatable"></b-input>
        </div>
      </div>
    </div>
    <div class="pagination mt-2">
      <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1" @click.prevent="minPage"> &lt; </button>
      <button class="mr-1" id="pageButton" v-else disabled> &lt; </button>
      <p class="mr-1">{{ currentPage }}</p>
      <p class="mr-1">/</p>
      <p class="mr-1">{{ totalPage }} </p>
      <button type="button" id="pageButton" v-if="currentPage < totalPage" @click.prevent="addPage"> > </button>
      <button id="pageButton" v-else disabled> > </button>
    </div>
    <div class="tableRow">
      <div class="inRow">
        <lord-icon
          v-if="isLoading"
          src="https://cdn.lordicon.com/dpinvufc.json"
          trigger="loop"
          style="width:300px;height:300px"
        ></lord-icon>
      </div>
      <table v-if="!isLoading" class="table-bordered table-hover" id="tableData">
        <thead>
          <tr>
            <th>Tanggal</th>
            <th>Jam</th>
            <th>Jenis</th>
            <th>User</th>
            <th>Keterangan</th>
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in dataTable" :key="e.id">
            <td>{{ formatDate(e.date) }}</td>
            <td>{{ e.time }}</td>
            <td>{{ e.type == 1 ? 'Mobil Keluar / Masuk' : 'PO' }}</td>
            <td>{{ e.User.username }}</td>
            <td>{{ e.notes }}</td>
            <td class="text-center">
              <button @click.prevent="openImage(e.photo)" title="Open picture">
                <img src="../assets/image.png" alt="" />
              </button>
              <button @click.prevent="showLocation(e)" title="View location">
                <img src="../assets/location.png" alt="" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1" @click.prevent="minPage"> &lt; </button>
        <button class="mr-1" id="pageButton" v-else disabled> &lt; </button>
        <p class="mr-1">{{ currentPage }}</p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{ totalPage }} </p>
        <button type="button" id="pageButton" v-if="currentPage < totalPage" @click.prevent="addPage"> > </button>
        <button id="pageButton" v-else disabled> > </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../API/axios';
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  name: 'Voucher',
  data() {
    return {
      id: 0,
      dataTable: [],
      keyword: '',
      type: 0,
      endDate: '',
      startDate: '',
      currentPage: 1,
      perPage: 20,
      totalPage: 1,
      isLoading: false,
    };
  },
  created() {
    this.$store.dispatch('getBranch');
    this.getDatatable();
    this.clearData();
  },
  computed: {
    branches() {
      return this.$store.state.branches;
    },
  },
  methods: {

    async clearData() {
      try {
        let { data } = await axios({
          method: 'GET',
          url: '/mobile/cleardata',
        });

      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async getDatatable() {
      try {
        let { data } = await axios({
          method: 'GET',
          url: '/mobile',
          params: {
            page: this.currentPage,
            limit: this.perPage,
            keyword: this.keyword,
            type: this.type,
            endDate: this.endDate,
            startDate: this.startDate,
          },
          headers: {
            access_token: localStorage.getItem('access_token'),
          },
        });
        this.dataTable = data.data2.result;
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage);
        this.totalPage = totalPage;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    showLocation(e) {
      const latitude = parseFloat(e.latitude);
      const longitude = parseFloat(e.longtitude);
      console.log(latitude, longitude,"==========");
      if (!isNaN(latitude) && !isNaN(longitude)) {
        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
        window.open(url, '_blank');
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Koordinat tidak valid',
          icon: 'error',
        });
      }
    },
    openImage(text) {
      
      window.open(`https://dbsikuraja.otnielpangkung.com/${text}`);
      // window.open(`http://localhost:3001/${text}`);
    },
    formatDate(date) {
      let result = '';
      if (date) result = moment(date).format('DD-MM-YYYY');
      return result;
    },
    addPage() {
      this.currentPage = this.currentPage + 1;
      this.getDatatable()
    },
    minPage() {
      this.currentPage = this.currentPage - 1;
      this.getDatatable()
    },
  },
};
</script>

<style scoped>
.cardRow {
  /* display: flex; */
  width: 100%;
  /* background-color: aqua; */
}
</style>
