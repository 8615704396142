<template>
  <div class="mainPage">
    <div class="loginBox">
      <p id="headTitle">SIKU RAJA</p>
      <p id="title">SISTEM KEUANGAN KURNIA JAYA KARYA</p>
      <form @submit.prevent="login">
        <!-- <p id="inputTitle">Username</p> -->
        <input type="text" required v-model="username" placeholder="Username">
        <!-- <p id="inputTitle">Password</p> -->
        <input type="password" v-model="password" required placeholder="Password">
        <button type="submit">LOGIN</button>
        <!-- <p id="copyright">Honesty and hard work are the two main pillars of character. - Mahatma Gandhi</p> -->
      </form>
    </div>
  </div>
</template>

<script>
import axios from '../API/axios';
import Swal from "sweetalert2";
import moment from 'moment';
import '../assets/Miraculous&Christmas.ttf'
export default {
  name: "LoginPage",
  data() {
    return {
      username: '',
      password: "",
      number: 0,
      numberFormatted: '0',
      audioSrc: require('../assets/okgas.mp3')
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  mounted() {
    // this.tryPlayMusic();
  },
  methods: {
    async login() {
      try {
        let data = await axios({
          method: 'post',
          url: "/user/login",
          data: {
            username: this.username,
            password: this.password,
          },
        });
        let date = moment(new Date()).format("YYYY-MM-DD");
        console.log(data, "data");
        localStorage.setItem('access_token', data.data.access_token);
        localStorage.setItem('role', data.data.role);
        localStorage.setItem('username', data.data.username);
        localStorage.setItem('date', date);
        Swal.fire("Semangat Pagi", "Semangat Pagi, Semangat Bekerja", "success");
        this.$store.commit("setIsLogin", true);
        this.$store.dispatch('getLocalStorage');
        this.$router.push("/");
      } catch (err) {
        Swal.fire("Maaf", "Password/username anda salah", "error");
      }
    },
    // tryPlayMusic() {
    //   console.log("Trying to play music");
    //   const audioElement = this.$refs.audio;

    //   // Coba langsung memutar audio saat komponen di-mount
    //   audioElement.play().then(() => {
    //     console.log("Music started playing automatically");
    //     this.playError = false;
    //   }).catch((error) => {
    //     console.error("Error trying to play music automatically:", error);
    //     this.playError = true;
    //   });
    // },
    // playMusic() {
    //   console.log("Play button clicked");
    //   const audioElement = this.$refs.audio;

    //   // Pemutaran manual jika gagal otomatis
    //   audioElement.play().then(() => {
    //     console.log("Music started playing");
    //     this.playError = false;
    //   }).catch((error) => {
    //     console.error("Error trying to play music:", error);
    //     this.playError = true;
    //   });
    // }
  },
};
</script>

<style scoped>
.mainPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* background-image: url(../assets/1.jpeg); */
  /* Set background-size to 200px width */
  background-size: 800px auto;
  /* Repeat the image using background-repeat */
  background-repeat: repeat;
}



.loginBox {
  background-color: rgba(22, 37, 195, 0.5);
  border-radius: 20px;
  padding: 20px 40px 32px 40px;
  /* min-width: 40rem; */
  width: 25rem;
}
img {
  margin-bottom: 8px;
  max-width: 20rem;
}
#headTitle {

  color: #FFFFFF;
  font-size: 28px;
  margin: 0px;
  font-weight: 600;
  text-align: left;
}

#title {
  text-align: left;
  font-size: 16px;
  color: #FFFFFF;
  margin: 0px;
  margin-bottom: 10px;
  margin-bottom: 70px;
  font-weight: 500;
}

#inputTitle {
  font-family: 'Mountains of Christmas', serif;
  color: #e8e8e8;
  text-align: left;
  font-size: 16px;
  font-weight: 800;
  margin: 0;
}
.inCol {
    align-items: center;
    /* justify-content: center; */
    /* text-align: center; */
    display: flex;
    flex-direction: column;
}
input  {
  margin-bottom: 5px;
  font-size: 16px;
  text-align: center;
  padding: 6px 20px;
  border-radius: 8px;
  border: 1px rgb(188 183 164) solid;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background-color: rgba(255, 255, 255, 0.876);
}
input:active {

}

button {
  margin-top: 4px;
    padding: 4px 0;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    background-color: #6A80B9;
    border-radius: 8px;
    border: none;
}

button:hover {
  /* color: #ededed;
  background-color: #EA9023; */
  color: #d73616; 
  background-color: transparent;
  font-weight: 800;
}

/* button a: */

form {
  display: flex;
  flex-direction: column;
}

#copyright {
  
  margin-top: 4px;
  color: #0d0f0e;
  font-weight: 500;
  font-size: 14px;
}

/* Snowflakes (continued) */
.snowflakes {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}




</style>
